import { WarningSection } from 'components/card/warning-section';
import { ApplicationContent } from 'components/layouts/application/application-content';
import { SubHeader } from 'components/layouts/sub-header';
import { VerticalSpace } from 'components/space/vertical-space';
import { SecondaryText } from 'components/typography';
import { COLORS } from 'helpers/constants';
// import { BackgroundLayout } from "components/layouts/main-layout"

// eslint-disable-next-line import/no-default-export
export const NotFound = () => {
  return (
    <>
      {/* <BackgroundLayout> */}
      <SubHeader icon="" text="" />
      <VerticalSpace
        style={{
          margin: '100px auto',
          minWidth: '600px',
        }}
      >
        <ApplicationContent>
          <WarningSection size="20px" style={{ maxWidth: '600px' }}>
            {/* Դիմումների ընդունման գործընթացն ավարտված է */}
            <SecondaryText style={{ fontSize: '16px' }} color={COLORS.PRIMARY.GRAY_DARK}>
              Հարգելի՛ քաղաքացի,
            </SecondaryText>{' '}
            <br />
            <SecondaryText style={{ fontSize: '16px' }} color={COLORS.PRIMARY.GRAY_DARK}>
              «Լեռնային ղարաբաղից բռնի տեղահանվածներին առաջնային սպառողական ծախսերը հոգալու համար սոցիալական աջակցության
              միջոցառում» հարթակում դիմում մուտքագրելու հնարավորությունը դադարեցված է 31․05․2024 թվականից:
            </SecondaryText>
          </WarningSection>
        </ApplicationContent>
      </VerticalSpace>
      {/* </BackgroundLayout> */}
    </>
  );
};
