import styled from 'styled-components';

const Title = styled.span`
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    height: 140px;
    line-height: 18px;
  }
`;

export const PageTitle = () => {
  return (
    <Title>
        ԼԵՌՆԱՅԻՆ ՂԱՐԱԲԱՂԻՑ ԲՌՆԻ ՏԵՂԱՀԱՆՎԱԾՆԵՐԻՆ ԱՌԱՋՆԱՅԻՆ ՍՊԱՌՈՂԱԿԱՆ ԾԱԽՍԵՐԸ ՀՈԳԱԼՈՒ ՀԱՄԱՐ ՍՈՑԻԱԼԱԿԱՆ ԱՋԱԿՑՈՒԹՅԱՆ ՄԻՋՈՑԱՌՈՒՄ
    </Title>
  );
};
