import { Form } from 'antd';
import { UploadApplicationDocument } from 'components/upload/upload-application-documents';
import { DOCUMENT_TYPES } from 'helpers/constants';

export const FamilyFileUpload = ({ memberKey, isRequired = false }) => {
  const form = Form.useFormInstance();
  const familyMembers = Form.useWatch(['familyData', 'familyMembers', memberKey]);

  return (
    <UploadApplicationDocument
      isRequired={isRequired}
      disabled={!familyMembers?.documentNumber}
      infoText="Խնդրում ենք կցել անհրաժեշտ փաստաթղթերը"
      userData={familyMembers}
      checksumName={[memberKey, 'familyMemberChecksum']}
      attachedDocumentType={DOCUMENT_TYPES.FAMILY_MEMEBER}
      tempApplicationId={form.getFieldValue('tempApplicationId')}
    />
  );
};
