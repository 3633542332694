import { DatePicker as DatePickerComponent } from 'antd';
import styled from 'styled-components';
import hy from 'antd/locale/hy_AM';
import 'dayjs/locale/hy-am';
import dayjs from 'dayjs';

const dateFormat = 'DD/MM/YYYY';
const mask = '__/__/____';

dayjs.locale('hy-am');

export const Datepicker = styled((props) => (
  <DatePickerComponent locale={hy} format={dateFormat} placeholder={mask} {...props} />
))``;
