import {FormSectionFullCard} from 'components/card/form-section-full-card';
import {FormItem} from '../form-item';
import {SecondaryText, Text} from 'components/typography';
import {useState} from "react";
import {Radio, Space} from "antd";
import {COLORS} from "../../../helpers/constants";

export const ConsentToBankAccountVerification = () => {
    const [checked, setChecked] = useState(false);

    return (
        <FormSectionFullCard>
            <Text strong>
                Տալիս եմ համաձայնությունս՝ իմ բանկային հաշիվներում առկա դրամական միջոցների չափը 2023 թվականի հոկտեմբերի 2-ի դրությամբ 2000000 դրամը չգերազանցելու վերաբերյալ տեղեկություն ստանալու համար։
            </Text>
            <FormItem propName="checked" name="consentToBankAccountVerification" rules={[{required: true}]}>
                <Radio.Group>
                    <Space>
                        <Radio onMouseEnter={() => setChecked(true)} value={checked}>
                            <SecondaryText color={COLORS.PRIMARY.BLUE}>Համաձայն եմ</SecondaryText>
                        </Radio>
                    </Space>
                </Radio.Group>
            </FormItem>
        </FormSectionFullCard>
    );
};
