import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useMemo } from 'react';
import { SubHeader } from '../sub-header';
import { ReactComponent as File } from '../../../icons/file.svg';

export const VerifyLayout = ({ isEdit = false, hideHeader = false }) => {
    const context = useMemo(() => ({ isEdit }), [isEdit]);
    return (
        <Layout style={{ background: 'transparent' }}>
            {!hideHeader && (
                <SubHeader icon={<File />} text="Ստուգել հայտի կարգավիճակը" />
            )}
            <Outlet context={context} />
        </Layout>
    );
};
