import { FormSectionFullCard } from 'components/card/form-section-full-card';
import { FormItem } from '../form-item';
import { Checkbox } from '../../checkbox';
import { VerticalSpace } from 'components/space/vertical-space';
import { Text } from 'components/typography';

export const IsMyDataTrue = () => {
    return (
        <FormSectionFullCard>
            <VerticalSpace>
                <FormItem propName="checked" name="isDataAccurate" rules={[{ required: true }]}>
                    <Checkbox.Group>
                        <Checkbox value={true}>
                            <Text strong>
                                Հավաստում եմ, որ իմ կողմից ներկայացված տվյալները հավաստի են:
                            </Text>
                        </Checkbox>
                    </Checkbox.Group>
                </FormItem>
            </VerticalSpace>
        </FormSectionFullCard>
    );
};
