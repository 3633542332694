import { Col, Input, Row } from 'antd';
import { CommunitySelect } from '../select/community-select';
import { RegionsSelect } from '../select/regions-select';
import { SettlementSelect } from '../select/settlement-select';
import { VerticalSpace } from '../space/vertical-space';
import { Form } from './form';
import { FormItem } from './form-item';
import { BuildingTypesSelect } from '../select/building-types-select';
import { PostIndexSelect } from 'components/select/post-index-select';

export const PersonAddress = ({ formItemAddress = {} }) => {
  const regionId = Form.useWatch(formItemAddress.regionId.name);
  const communityId = Form.useWatch(formItemAddress.communityId.name);
  const form = Form.useFormInstance();

  const onRegionChange = () => {
    form.resetFields([
      formItemAddress.communityId.name,
      formItemAddress.settlementId.name,
      ...(formItemAddress.postIndex ? [formItemAddress.postIndex.name] : []),
    ]);
  };

  const onCommunityChange = () => {
    form.resetFields([formItemAddress.settlementId.name]);
  };

  return (
    <VerticalSpace>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12}>
          <FormItem label="Մարզ" {...formItemAddress.regionId}>
            <RegionsSelect
              labelInValue
              onChange={onRegionChange}
            />

          </FormItem>
          <FormItem hidden {...formItemAddress.region} />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <FormItem label="Համայնք" {...formItemAddress.communityId}>
            <CommunitySelect
              labelInValue
              onChange={onCommunityChange}
              regionId={regionId?.value}
            />
          </FormItem>
          <FormItem hidden {...formItemAddress.community} />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12}>
          <FormItem label="Բնակավայր" {...formItemAddress.settlementId}>
            <SettlementSelect
              labelInValue
              communityId={communityId?.value}
            />
          </FormItem>
          <FormItem hidden {...formItemAddress.settlement} />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <FormItem label="Փողոց" {...formItemAddress.street}>
            <Input />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12}>
          <FormItem label="Կառույցի տեսակ" {...formItemAddress.buildingType}>
            <BuildingTypesSelect labelInValue />
          </FormItem>
          <FormItem hidden {...formItemAddress.buildingTypeId} />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <FormItem label="Կառույցի համար" {...formItemAddress.building}>
            <Input />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12}>
          <FormItem label="Բնակարան" {...formItemAddress.apartment}>
            <Input />
          </FormItem>
        </Col>
        {formItemAddress.postIndex && (
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Փոստային դասիչ" {...formItemAddress.postIndex}>
              <PostIndexSelect
                regionId={regionId?.value}
              />
            </FormItem>
          </Col>
        )}
      </Row>
    </VerticalSpace>
  );
};
