import {Col, Form, Radio, Row, Space} from 'antd';
import {FormSectionCard} from 'components/card/form-section-card';
import {FormItem} from 'components/form/form-item';
import {BanksSelect} from 'components/select/banks-selec';
// import { PaymentMethodsSelect } from 'components/select/payment-methods-selec';
import {VerticalSpace} from 'components/space/vertical-space';
// import { PAY_METHOD } from 'helpers/constants';
import {useState} from 'react';
import {Input} from "../../../input";
import {SecondaryText} from "../../../typography";
import {COLORS} from "../../../../helpers/constants";
import {Info} from "../../../typography/info";

export const BankData = () => {
    // const [isBankRequired, setBankRequired] = useState(true);
    const form = Form.useFormInstance();
    const [hasBankAccount, setHasBankAccount] = useState();
    const bankAccount = Form.useWatch("hasBankAccount", {preserve: true});

    // Clear the selected bank when radio changes
    const handleRadioChange = (value) => {
        setHasBankAccount(value);
        if (!value) {
            form.resetFields(["accountNumber"]);
        }
        form.resetFields(["bankId"]);
    };

    return (
        <VerticalSpace>
            <FormSectionCard title="Բանկի ընտրություն">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <FormItem label={"Ունե՞ք բանկում քարտային հաշիվ"} name={'hasBankAccount'}
                                  rules={[{required: true}]}>
                            <Radio.Group onChange={(e) => handleRadioChange(e.target.value)}>
                                <Space>
                                    <Radio value={true}>
                                        <SecondaryText color={COLORS.PRIMARY.BLUE}>Այո</SecondaryText>
                                    </Radio>
                                    <Radio value={false}>
                                        <SecondaryText color={COLORS.PRIMARY.BLUE}>Ոչ</SecondaryText>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </FormItem>
                    </Col>
                    {bankAccount !== undefined &&
                        <Col xs={24} sm={24} md={12}>
                            <FormItem
                                label={bankAccount ? "Սպասարկող բանկը" : "Նախընտրելի բանկը(Նշեք բանկը, որում կցանականաք ունենալ քարտային հաշիվ)"}
                                name="bankId" rules={[{required: true}]}>
                                <BanksSelect hasBankAccount={hasBankAccount}/>
                            </FormItem>
                        </Col>
                    }
                    {bankAccount &&
                        <Col xs={24} sm={24} md={12}>
                            <FormItem
                                label={bankAccount ? "Քարտի համարը (Լրացրեք քարտի վրա գրված 16 նիշը)" : "Հաշվեհամար"}
                                name="accountNumber"
                                rules={[
                                    {required: true, message: 'Ուշադիր․ Պարտադիր դաշտ'},
                                    {len: 16, message: 'Տվյալ դաշտը պետք է պարունակի 16 նիշ'}
                                ]}>
                                {/*<PaymentMethodsSelect*/}
                                {/*  onChange={(value) => {*/}
                                {/*    setBankRequired(value === PAY_METHOD.BANK);*/}
                                {/*  }}*/}
                                {/*/>*/}
                                <Input type="number" maxLength={16}/>
                            </FormItem>
                        </Col>
                    }
                </Row>
                {(!bankAccount && bankAccount !== undefined) &&
                    <Row>
                        <Col span={24}>
                            <Info
                                text='Ընտրելիս հաշվի առեք, որ բանկի կողմից թողարկված քարտը կստանաք անվճար առաքմամբ։'></Info>
                            <Info
                                text='ԱԿԲԱ բանկի ընտրության դեպքում ծառայությունը կստանաք acba digital հավելվածը ներբեռնելուց և առցանց նույնականացում անցնելուց հետո։'></Info>
                        </Col>
                    </Row>
                }
            </FormSectionCard>
        </VerticalSpace>
    );
};
