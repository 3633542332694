import { Select as SelectComponent } from 'antd';
// import { placeholderSize } from '../../helpers/styles';
import styled from 'styled-components';

export const Select = styled(SelectComponent)`
  && {
    .ant-select-selector {
      background: linear-gradient(91.78deg, rgba(255, 255, 255, 0.64) 6.81%, rgba(255, 255, 255, 0.16) 100%);
      touch-action: manipulation !important;
    }
  }
`;

export { FamilyRelationshipSelect } from './family-relationship-select';
