import { ActualResidentialAddress } from '../actual-residential-address';
import { Form } from '../../form';
import { actualAddress, formItemStepTwoParent, registrationAddress } from 'pages/application/constants';
import { useEffect } from 'react';
import { useMatches } from 'react-router-dom';

export const RegistrationAddress = ({
  formItemRegAddress = {},
  formItemActualAddressStepTwo,
  // formItemAddressType,
  viewMode = false,
  actualAddressAutoFill = false,
}) => {
  const form = Form.useFormInstance();
  const isActualAddressMatch = Form.useWatch(formItemRegAddress.isActualAddressMatch.name);
  const regAddress = form.getFieldValue([formItemStepTwoParent, registrationAddress]);

  const actionType = [...useMatches()].slice(-1)[0].handle;

  useEffect(() => {
    if (actualAddressAutoFill && isActualAddressMatch && actionType !== 'edit') {
      form.setFieldsValue({
        [formItemStepTwoParent]: {
          [actualAddress]: {
            regionId: regAddress.regionId,
            region: regAddress.region,
            communityId: regAddress.communityId,
            community: regAddress.community,
            settlementId: regAddress.settlementId,
            settlement: regAddress.settlement,
            street: regAddress.street,
            building: regAddress.building,
            apartment: regAddress.apartment,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActualAddressMatch]);

  return <ActualResidentialAddress viewMode={viewMode} formItemActualAddressStepTwo={formItemActualAddressStepTwo} />;
};
