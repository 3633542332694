import {FormSectionFullCard} from 'components/card/form-section-full-card';
import {FormItem} from '../form-item';
import {Radio, Space} from 'antd';
import {SecondaryText, Text} from 'components/typography';
import {COLORS} from 'helpers/constants';

export const GiveDataTo3rdParty = () => {
    return (
        <FormSectionFullCard>
            <Text strong>
                Ես և իմ ընտանիքի անդամները համաձայն ենք ներկայացված տվյալները տրամադրել ԱՍՀՆ հետ համագործակցող երրորդ
                կողմին՝ ԼՂ-ից բռնի տեղահանված անձանց սոցիալական աջակցություն ստանալու նպատակով։
            </Text>
            <FormItem propName="checked" name="hasAgreedToPassTo3rdParty" rules={[{required: true}]}>
                <Radio.Group>
                    <Space>
                        <Radio value={true}>
                            <SecondaryText color={COLORS.PRIMARY.BLUE}>Համաձայն եմ</SecondaryText>
                        </Radio>
                        <Radio value={false}>
                            <SecondaryText color={COLORS.PRIMARY.BLUE}>Համաձայն չեմ</SecondaryText>
                        </Radio>
                    </Space>
                </Radio.Group>
            </FormItem>
        </FormSectionFullCard>
    );
};
