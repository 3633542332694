import { Col, Row, Spin } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';

import { VALIDATE_MESSAGES } from '../../helpers/constants';
import { Button } from '../button';
// import { WarningSection } from '../card/warning-section';
import { VerticalSpace } from '../space/vertical-space';
// import { Text } from '../typography';
import { PersonMainData } from './person-main-data';
import { useState } from 'react';
import { Form } from './form';
import { useGetValidateUser } from '../../api/benefit/use-get-validate-user';
import { ErrorText } from '../typography/error-text';
import { calculateAge, errorMessage, errorMessageAdd, errorMessageAge } from '../../helpers/utils';
import { SuccessText } from '../typography/success-text';
import { PersonContactForm } from './PersonContactForm';
import { FormItem } from './form-item';
import { useApplication } from 'context/applicaton-context';
import { Input } from 'components/input';

const buttonStyle = {
  height: 'auto',
  display: 'flex',
  whiteSpace: 'normal',
};

export const PersonDataSearchForm = ({
  formItemProp,
  url,
  onSuccess,
  onSelect,
  parent = [],
  locationRes,
  // docItemProps,
  setDisabled = false,
  onRemove,
  isApplicant = true,
  checkAge = false,
}) => {
  const [search, setSearch] = useState(false);
  const form = Form.useFormInstance();

  const isApproved = Form.useWatch([...parent, ...(formItemProp?.isApproved?.name || [])], { preserve: true });
  // eslint-disable-next-line no-unused-vars
  const { jump, step } = useApplication();
  const onSearch = () => {
    const ssn = form.getFieldValue([...parent, ...formItemProp.ssn.name]);
    const ssnHasError = form.getFieldError([...parent, ...formItemProp.ssn.name]);
    const firstName = form.getFieldValue([...parent, ...formItemProp.fname.name]);
    const lastName = form.getFieldValue([...parent, ...formItemProp.lname.name]);

    if (!ssn) {
      form.setFields([
        {
          name: [...parent, ...formItemProp.ssn.name],
          errors: [VALIDATE_MESSAGES.required],
        },
      ]);
    }

    if (!firstName) {
      form.setFields([
        {
          name: [...parent, ...formItemProp.fname.name],
          errors: [VALIDATE_MESSAGES.required],
        },
      ]);
    }

    if (!lastName) {
      form.setFields([
        {
          name: [...parent, ...formItemProp.lname.name],
          errors: [VALIDATE_MESSAGES.required],
        },
      ]);
    }
    // if (!birthdate) {
    //   form.setFields([
    //     {
    //       name: [...parent, ...formItemProp.birthdate.name],
    //     },
    //   ]);
    // }
    // if (!birthdate) {
    //   form.setFields([
    //     {
    //       name: [...parent, ...formItemProp.birthdate.name],
    //       errors: [VALIDATE_MESSAGES.required],
    //     },
    //   ]);
    // }
    // if (!birthdate) {
    //   form.setFields([
    //     {
    //       name: [...parent, ...formItemProp.birthdate.name],
    //       errors: [VALIDATE_MESSAGES.required],
    //     },
    //   ]);
    // }

    if (ssn && firstName && lastName && !ssnHasError.length) {
      setSearch({ ssn, firstName, lastName });
    }
  };

  const { isInitialLoading } = useGetValidateUser(`${url}?isApplicant=${isApplicant}`, search, {
    enabled: !!search.ssn,
    onSuccess: (data) => {
      onSuccess(data);
      if (checkAge && calculateAge(data.birthDate) >= 18) {
        errorMessageAge();
        formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], false);
      } else {
        formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], !!data);
        onSearch();
      }
    },
    select: (data) => onSelect?.(data) || data.data,
    onError: (error) => {
      if (!error.response.data.Data) {
        errorMessage(error);
        formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], false);
        form.resetFields();
      } else {
        errorMessageAdd(
          error,
          () => {
            form.setFieldValue('applicationId', error.response.data.Data);
            jump(step + 1);
          },
          () => {
            formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], false);
            form.resetFields();
          }
        );
      }
      onSearch();
    },
  });

  return (
    <Spin spinning={isInitialLoading}>
      <VerticalSpace>
        <PersonMainData formItemProp={formItemProp} disabled={setDisabled} section="general" />
        <FormItem hidden name="applicationId">
          <Input />
        </FormItem>
        {(locationRes === null || locationRes === undefined) && (
          <Row justify="center">
            <Col>
              {setDisabled ? (
                <Button
                  size="large"
                  icon={<DeleteOutlined />}
                  type="default"
                  onClick={() => onRemove([url, search], () => setSearch(false))}
                >
                  Հեռացնել
                </Button>
              ) : (
				        <Button
                  size="large"
                  shape="round"
                  icon={<SearchOutlined />}
                  type="default"
                  onClick={onSearch}
                  style={buttonStyle}
                >
                  <span>Սեղմել կոճակը՝ անձնական տվյալների նույնականացման համար</span>
                </Button>
              )}
            </Col>
          </Row>
        )}
        <PersonMainData formItemProp={formItemProp} disabled={setDisabled} section="secondary" />

        {isApproved === false && <ErrorText text="Նույնականացումը չի հաջողվել" />}
        {isApproved === true && <SuccessText text="Անձը հաջողությամբ նույնականացվեց" />}
        {formItemProp.contact && <PersonContactForm formItemContactProp={formItemProp.contact} parent={parent} />}
        {/* {(locationRes === null || locationRes === undefined) && (
          <WarningSection>
            <Text color={COLORS.SECONDARY.GRAY_DARK}>
              <p>Նույնականացման տվյալները անհրաժեշտ է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան։</p>
            </Text>
          </WarningSection>
        )} */}
      </VerticalSpace>
    </Spin>
  );
};
