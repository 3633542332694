import { FormItem } from 'components/form/form-item';
import { FormSectionCard } from '../../../card/form-section-card';
import { Radio, Space } from 'antd';
import { formItemStepTwoParent } from 'pages/application/constants';
import { SecondaryText } from 'components/typography';
import { COLORS } from 'helpers/constants';

export const PeopleInSameAddress = () => {
  return (
    <FormSectionCard title="Ունե՞ք արդյոք անչափահաս երեխաներ">
      <FormItem
        propName="checked"
        name={[formItemStepTwoParent, 'hasMembersInSameAddress']}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Space>
            <Radio value={true}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Այո</SecondaryText>
            </Radio>
            <Radio value={false}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Ոչ</SecondaryText>
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
    </FormSectionCard>
  );
};
