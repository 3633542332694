import { Steps as StepsComponent } from 'antd';
import styled from 'styled-components';
import { useApplication } from '../../context/applicaton-context';
import { CheckCircleFilled } from '@ant-design/icons';

import { ReactComponent as StepIn } from '../../icons/step-in.svg';
import { ReactComponent as StepNotVisited } from '../../icons/step-not-visited.svg';
import { useMemo } from 'react';

const Steps = styled(StepsComponent)`
  .ant-steps-item-title {
    font-size: 18px;
  }

  .ant-steps-item-container {
    padding-bottom: 40px;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const steps = [
  {
    title: 'Անձնական տվյալներ ',
  },
  {
    title: 'Անչափահասի տվյալներ',
  },
];

export const ApplicationSteps = ({ collapsed }) => {
  const { step, jump, finishedSteps } = useApplication();

  const onChange = (value) => {
    jump(value);
  };

  const items = useMemo(
    () =>
      steps.map((item, index) => ({
        ...(collapsed ? { title: '' } : item),
        ...(finishedSteps.includes(index)
          ? { icon: <CheckCircleFilled style={{ fontSize: 32 }} /> }
          : index === step
          ? { icon: <StepIn /> }
          : { icon: <StepNotVisited /> }),
      })),
    [finishedSteps, step, collapsed]
  );

  return <Steps direction="vertical" size="default" current={step} onChange={onChange} items={items} />;
};
