import { Button as ButtonComponent } from 'antd';
import { textSizeMedia } from '../typography/text';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';

export const Button = styled(ButtonComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${textSizeMedia}
  &.ant-btn-default {
    background-color: ${COLORS.PRIMARY.GREEN_TEAL};
    color:${COLORS.PRIMARY.WHITE};
    &:hover {
      background-color: ${COLORS.PRIMARY.GREEN_TEAL};
       color: ${COLORS.PRIMARY.WHITE}
    }

  } 
  
  &.ant-btn-default:disabled {
  }

  &.ant-btn-default:not(:disabled) {
  }
`;

export { MainNavigationButton } from './main-navigation-button';
export { SubmitButton } from './submit-button';
export { ManageFamilyMemberButton } from './manage-family-member-button';
