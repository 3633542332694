import { Layout } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../helpers/constants';
import { ApplicationSteps } from '../steps/application-steps';

const { Sider: SiderComponent } = Layout;

const Sider = styled(SiderComponent)`
  padding-top: 145px;
  && {
    background-color: ${COLORS.PRIMARY.BLUE_LIGHT};
    box-shadow: 4px 4px 10px rgba(71, 26, 127, 0.1);
    /* position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    height: 100vh;
    width: 100vw; */
  }

  .ant-steps {
    padding-left: 35px;
  }

  &.ant-layout-sider-collapsed {
    .ant-steps {
      padding-left: 20px;
    }
  }
`;

const isMobile = window.innerWidth <= 768;

export const ApplicationSider = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
      {isMobile ? (
        <></>
      ) : (
        <Sider width={400} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <ApplicationSteps collapsed={collapsed} />
        </Sider>
      )}
    </>
  );
};
