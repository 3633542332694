import { VerticalSpace } from 'components/space/vertical-space';
import { COLORS } from '../../helpers/constants';
import { Text } from './text';
import { WarningSection } from 'components/card/warning-section';

export const FormRequiredInfo = ({ isFamily = false }) => {
  return (
    <VerticalSpace size="small">
      <WarningSection>
        <Text color={COLORS.SECONDARY.GRAY_DARK}>
          {isFamily && <p>Անչափահաս երեխայի համար հայտը պետք է լրացնի ծնողը (խնամակալը կամ հոգաբարձուն)՝ հակառակ դեպքում դիմումը կմերժվի)</p>}
          <span style={{ color: COLORS.ALERT.RED }}>*</span>-ով նշված դաշտերում տվյալների մուտքագրումը պարտադիր է։
          <p>Նույնականացման տվյալներն անհրաժեշտ է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան։</p>
          <p>Աջակցությանը դիմելու համար պարտադիր է ունենալ քարտային հաշիվ (հակառակ դեպքում ՝ Ձեր դիմումը կմերժվի)։</p>
          <p>75 տարին լրացած, ինչպես նաև առաջին և երկրորդ խմբի հաշմանդամություն ունեցող անձինք, ովքեր առաժմ չունեն քարտային հաշիվ, կարող են լրացնել դիմումը՝ նշելով նախընտրելի բանկը, որտեղ կցանկանան ունենան հաշիվ։</p>
          <p>Յուրաքանչյուր չափահաս անձ պետք է լրացնի իր դիմումը առանձին։</p>
          <p>Անչափահասի դեպքում դիմումը պետք է լրացվի ծնողներից մեկի (կամ խնամակալի կամ հոգաբարձուի) կողմից։</p>
        </Text>
      </WarningSection>
    </VerticalSpace>
  );
};
