import { Card, Col, Row } from 'antd';
import { Form } from '../../components/form/form';
import { FormItem } from '../../components/form/form-item';
import { ArmenianInput } from 'components/input/armenian-input';
import { VerticalSpace } from 'components/space/vertical-space';
import { Button } from 'components/button';
import { Info } from 'components/typography/info';
import { SearchOutlined } from '@ant-design/icons';
import { useSaveApplication } from 'api/application/use-save-application';
import { URL_CHECK_STATUS } from 'api/application/constants';
import { errorMessage } from 'helpers/utils';
import { Notification } from 'components/modal/Notification';
import { useState } from 'react';
import { Input } from 'components/input';

export const Verify = () => {
  const [modalOpenText, setModalOpenText] = useState('');
  const [form] = Form.useForm();

  const { mutate } = useSaveApplication(URL_CHECK_STATUS, {
    onSuccess: (data) => {
      setModalOpenText(data?.data);
    },
    onError: errorMessage,
  });

  const onFinish = (values) => {
    mutate({
      ...values
    });
  };
  const handleCancel = () => {
    setModalOpenText('');
    form.resetFields();
  };

  return (
    <>


      <Form
        name="verify-form"
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={onFinish}
      >
        <Card
          title={false}
          bordered={false}

        >
          <VerticalSpace size="small">
            <Row justify="center" align="middle" style={{ minHeight: '50vh' }}>
              <Col xs={24} md={16}>
                <VerticalSpace>
                  <Row gutter={20}>
                    <Col>
                      <Info text="Հայտի կարգավիճակը ստուգելու համար լրացրեք անհրաժեշտ դաշտերը և սեղմեք որոնել հրահանգը։"></Info>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col xs={24} md={12}>
                      <FormItem label="Անուն" rules={[{ required: true }]} name="firstName">
                        <ArmenianInput />
                      </FormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      <FormItem label="Ազգանուն" rules={[{ required: true }]} name="lastName">
                        <ArmenianInput />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} offset={6} xs={24} md={12}>
                      <FormItem label="Անձնագիր կամ ՀԾՀ կամ նույնականացման քարտ" rules={[{ required: true }]} name="documentNumber">
                        <Input />
                      </FormItem>
                    </Col>
                  </Row>

                  <Row justify="center">
                    <Col>
                      <Button size="large" shape="round" icon={<SearchOutlined />} type="default" htmlType="submit">
                        Որոնել
                      </Button>
                    </Col>
                  </Row>
                  {modalOpenText && (
                    <Notification title="" footer={null} open={true} centered onCancel={handleCancel}>
                      <p style={{ color: '#FA396B' }}>{modalOpenText}</p>
                    </Notification>
                  )}
                </VerticalSpace>
              </Col>
            </Row>
          </VerticalSpace>
        </Card>
      </Form>
    </>
  );
};
