import { Layout } from 'antd';
import { screenSize } from 'helpers/constants';
import styled from 'styled-components';

const { Content } = Layout;

const WrapperContent = styled(Content)`
  margin: 40px 20px;
  @media (max-width: ${screenSize.md}) {
    margin: 20px 0px;
  }
`;

export const ApplicationContent = (props) => <WrapperContent {...props} />;
