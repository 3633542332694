// import { SubmitButton } from '../../components/button';
import { AddFamilyMember } from '../../components/form/application/family/add-family-member';
// import { HasFamilyMember } from '../../components/form/application/family/has-family-member';
import { VerticalSpace } from '../../components/space/vertical-space';
import { useApplication } from '../../context/applicaton-context';
import { FormRequiredInfo } from 'components/typography/form-required-info';
import { GiveDataTo3rdParty } from 'components/form/application/give-data-to-3rd-party';
import { Form } from '../../components/form/form';
import { SuccessModal } from 'components/modal/success-modal';
import { ConsentToBankAccountVerification } from 'components/form/application/consent-to-bank-account-verification';
import { IsMyDataTrue } from '../../components/form/application/is-mydata-true'

export const ThirdStep = () => {
  const { step, jump } = useApplication();
  const familyMembers = Form.useWatch(['familyData', 'familyMembers']);
  const form = Form.useFormInstance();
  const applicationId = form.getFieldValue('applicationId');

  const handleCancel = () => {
    jump(step - 1);
  };

  return (
    <VerticalSpace size="large">
      <FormRequiredInfo isFamily='true' />
      {/* <HasFamilyMember /> */}
      <AddFamilyMember />
      {familyMembers && familyMembers.length > 0 && <><ConsentToBankAccountVerification/> <GiveDataTo3rdParty /></>}

    <IsMyDataTrue />
	  <SuccessModal form={form} submitText="Հաստատել" canceltext="Նախորդ"  {...(applicationId !== undefined ? {} : { onCancel: handleCancel })} />
      {/* 
      <SubmitButton
        submitText="Հաստատել"
        canceltext="Նախորդ"
        onCancel={() => {
          jump(step - 1);
        }}
      /> */}
    </VerticalSpace>
  );
};
