import { ManageFamilyMemberButton } from 'components/button';
import { Form } from '../../form';
import { FamilyFormFormView } from './family-member-form-view';
import { ReactComponent as PlusCircle } from '../../../../icons/plus-circle.svg';
import { COLORS } from 'helpers/constants';
import { Space } from 'antd';
import { FamilyFormForm } from './family-member-form';

const FamilyMemberData = ({ fields, add, remove, viewMode = false }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
      {fields.map((field) =>
        viewMode ? (
          <FamilyFormFormView key={field.name} field={field} />
        ) : (
          <FamilyFormForm key={field.name} field={field} add={add} remove={remove} />
        )
      )}
    </div>
  );
};

export const AddFamilyMember = ({ viewMode = false }) => {
  return (
    <>
      <Form.List name={['familyData', 'familyMembers']} initialValue={[{ fname: '' }]}>
        {(fields, { add, remove }) => (
          <>
            <FamilyMemberData viewMode={viewMode} fields={fields} add={add} remove={remove} />

            <Space style={{ display: 'flex', justifyContent: 'center', paddingTop: ' 17px' }}>
              <ManageFamilyMemberButton
                doAction={add}
                text={'Ավելացնել ընտանիքի այլ անդամ'}
                icon={<PlusCircle style={{ width: '32px' }} />}
                size="large"
                type="default"
                shape="round"
                style={{ backgroundColor: '#FFFFFF', color: COLORS.PRIMARY.GREEN_TEAL }}
              />
            </Space>
          </>
        )}
      </Form.List>
    </>
  );
};
