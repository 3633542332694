import {forwardRef} from 'react';
import { Select } from '.';
import { URL_GET_BANKS } from '../../api/benefit/constants';
import { useGetFfromBenefit } from '../../api/benefit/use-get-dictionary';
import {FIELD_NAMES_DEFAULT_BANK} from './constants';
import {Form, Input} from "antd";

export const BanksSelect = forwardRef((props, ref) => {
  const form = Form.useFormInstance();
  const { data } = useGetFfromBenefit(URL_GET_BANKS);

  let filteredData = [];

  data.map((item) => {
    if(item?.selected) {
      filteredData.push(item)
    }
    if(item.id === form.getFieldValue('bankId')) {
      form.setFieldsValue({bankName: item.name})
    }
  });



  return (
      <>
          <Form.Item name={"bankName"} noStyle>
              <Input type="hidden" />
          </Form.Item>
          <Select
              fieldNames={FIELD_NAMES_DEFAULT_BANK}
              placeholder="Ընտրել"
              style={{ width: '100%' }}
              options={props.hasBankAccount ? data : filteredData}
              {...props}
              ref={ref}
          />
      </>
  );
});
