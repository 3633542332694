import { useApplication } from 'context/applicaton-context';
import { formItemPropStepTwo } from 'pages/application/constants';

/** use inside ApplicationProvider Provider */
export const useCitizenSearchValidationForm = () => {
  const { submittedData, cityzenData } = useApplication();

  if (cityzenData?.documentNumber) {
    for (const key in formItemPropStepTwo) {
      if (Object.prototype.hasOwnProperty.call(formItemPropStepTwo, key)) {
        formItemPropStepTwo[key].disabled = true;
      }
    }
  }

  return {
    ...formItemPropStepTwo,
    ssn: {
      ...formItemPropStepTwo.ssn,
      rules: [
        { required: true, message: 'Ուշադիր․ Պարտադիր դաշտ' },
        { min: 5, max: 15, message: 'Տեղեկատվությունը սխալ է լրացված' },
        {
          message: 'Դիմումատուն և Օրինական ներկայուցուցչը չեն կարող լինել նույն մարդը',
          validator: (_, value) => {
            if (submittedData?.profileData && value === submittedData?.profileData?.legalRepresentative?.ssn) {
              return Promise.reject('not allowed');
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
    },
  };
};
