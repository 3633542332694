import { Menu } from 'antd';
import styled from 'styled-components';
import { Link, useMatches } from 'react-router-dom';


import { MENU_TYPES } from './constants';

const SideMenu = styled(Menu)`
  border-radius: 4px;
  color: rgb(69, 111, 174);

  &.ant-menu {  
    li.ant-menu-item {
    line-height: normal;

      &-selected {
        background: #ffffff;
        border: 1px solid #0b847f; 
        background: #FFFFFF;
        border: 0.56px solid #E2DEF9;
        box-shadow: -28px 22px 45px rgba(27, 29, 66, 0.139423);
        a {
          color: rgb(69, 111, 174);
          background: #ffffff;

        }
        &:after {
          opacity: 0;
        }     
      }
      &-active {
        background-color: #ffffff
        border: 1px solid #ffffff;
        a {
          color: rgb(69, 111, 174); 

        } 
      }
    }
  }
`;
export const UserNavbar = () => {
  const match = useMatches();

  return (
    <SideMenu
      mode="inline"
      style={{
        borderRight: 0,
        background: 'transparent',
        padding: '28px 43px'
      }}
      selectedKeys={match.map((item) => item.pathname)}
      items={Object.entries(MENU_TYPES).map(([, menu]) => {
        return {
          key: menu.url,
          label: <Link to={menu.url}>{menu.label}</Link>,
          icon: menu.icon
        };
      })}
    />
  );
}; 
