import { createBrowserRouter } from 'react-router-dom';

import { PATHS } from './helpers/constants';
import { Application } from './pages/application';
import { Verify } from './pages/verify';

// import { ApplicationLayout } from './components/layouts/application/application-layout';
import { VerifyLayout } from './components/layouts/verify/verify-layout';
import { PublicRoutes } from './components/routes/public-route';
import { MainRoute } from 'components/routes/main-route';
import { NotFound } from 'pages/NotFound';

export const router = createBrowserRouter([
  {
    element: <MainRoute />,
    children: [
      {
        element: <PublicRoutes />,
        children: [
          {
            path: PATHS.ROOT,
            element: <NotFound />,
            // element: <ApplicationLayout />,
            children: [
              {
                path: PATHS.ROOT,
                element: <Application />,
              },
              {
                path: PATHS.APPLICATION,
                element: <Application />,
              },
            ],
          },
          {
            element: <VerifyLayout />,
            children: [
              {
                path: PATHS.VERIFY,
                element: <Verify />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
