import { useMutation } from '@tanstack/react-query';
import { client } from '../client';
import { VALIDATE_EMAIL_DOMAIN } from 'api/file/constants';

export const useValidateEmail = (restOptions) => {
  const mutation = useMutation(
    (values) => {
      return client.post(VALIDATE_EMAIL_DOMAIN, values, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    {
      ...restOptions,
      onSuccess: (data, variables, context) => {
        restOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
  return mutation;
};
