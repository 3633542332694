import { Col, Modal, Row, Space } from 'antd';
import { Button } from 'components/button';
import { ReactComponent as PlusCircle } from 'icons/plus-circle.svg';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { useState } from 'react';
import { COLORS } from 'helpers/constants';
import { formItemStepTwoParent } from 'pages/application/constants';

export const SuccessModal = ({ form, canceltext = 'Նախորդ', submitText = 'Հաստատել', onCancel }) => {
  const [showModal, setshowModal] = useState(false);

  const handleSubmit = () => {
    form.submit();
    setshowModal(false);
  };

  const addFamilyMembers = () => {
    form.setFieldsValue({
      [formItemStepTwoParent]: {
        hasMembersInSameAddress: true,
      },
    });
    setshowModal(false);
  };

  const handleCancel = () => {
    setshowModal(false);
  };
  return (
    <>
      <Row align="center" justify="center">
        <Col span={8}>
          <Space size="middle" align="center" style={{ width: '100%', justifyContent: 'center' }}>
            {onCancel && (
              <Button type="primary" shape="round" size="large" onClick={onCancel}>
                {canceltext}
              </Button>
            )}
            <Button type="primary" shape="round" size="large" onClick={() => setshowModal(true)}>
              {submitText}
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        open={showModal}
        onCancel={handleCancel}
        footer={
          <Row align="center" justify="center" gutter={[24, 24]}>
            <Col>
              <Space size="small" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <Button size="large" type="default" shape="round" onClick={() => handleSubmit()}>
                  Հաստատել
                </Button>
                <Button
                  size="large"
                  shape="round"
                  icon={<PlusCircle style={{ width: '32px' }} />}
                  style={{ backgroundColor: '#FFFFFF', color: COLORS.PRIMARY.GREEN_TEAL }}
                  onClick={() => addFamilyMembers()}
                >
                  Ավելացնել ընտանիքի նոր անդամ
                </Button>
              </Space>
            </Col>
          </Row>
        }
      >
        <VerticalSpace style={{ width: '100%' }} size="large">
          <Title style={{ textAlign: 'center' }} level={3}>
            ՈՒՇԱԴՐՈՒԹՅՈՒՆ
          </Title>
          <Row align="center" justify="center">
            <Col><p>Համոզվեք, որ լրացրել եք Ձեր ընտանիքի բոլոր անչափահաս երեխաների տվյալները:</p></Col>
          </Row>
        </VerticalSpace>
      </Modal>
    </>
  );
};
