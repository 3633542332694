import { useApplication } from '../../context/applicaton-context';
import { SecondStep } from './second-step';
import { Spin } from 'antd';
import { ThirdStep } from './third-step';

export const ManageSteps = () => {
  const { step, submittedData } = useApplication();

  return (
    <Spin spinning={false}>
      {(step === 0 || submittedData?.citizenData?.hasMembersInSameAddress === false) && <SecondStep />}
      {(step === 1 || (step === 2 && submittedData?.citizenData?.hasMembersInSameAddress === true)) && <ThirdStep />}
      {/* {step === 2 && <FourthStep />} */}
    </Spin>
  );
};
