import { Form } from 'antd';
import { COLORS, screenSize } from '../../helpers/constants';
import { SecondaryText } from '../typography';
import styled from 'styled-components';

const Item = styled(Form.Item)`
  @media (max-width: ${screenSize.md}) {
    font-size: 16px;
  }
`;

export const FormItem = ({ label, ...props }) => (
  <Item
    {...props}
    label={
      label &&
      ((typeof label === 'string' && <SecondaryText color={COLORS.PRIMARY.BLUE_DARK}>{label}</SecondaryText>) || label)
    }
  />
);
