import { CalendarOutlined, FileOutlined, SettingOutlined, CopyOutlined, BellOutlined, FileDoneOutlined } from '@ant-design/icons';

import { PATHS } from '../../helpers/constants';

export const MENU_TYPES = {
  SERVICES: {
    label: 'Ծառայություններ',
    url: `${PATHS.SERVICES}`,
    icon: <FileOutlined />
  },
  APPLICATIONS: {
    label: 'Իմ դիմումները',
    url: `${PATHS.APPLICATIONS}`,
    icon: <FileDoneOutlined />
  },
  NOTIFICATIONS: {
    label: 'Ծանուցումներ',
    url: `${PATHS.NOTIFICATIONS}`,
    icon: <BellOutlined />
  },
  SETTINGS: {
    label: 'Կարգավորումներ',
    url: `${PATHS.SETTINGS}`,
    icon: <SettingOutlined />
  },
  CALENDAR: {
    label: 'Օրացույց',
    url: `${PATHS.CALENDAR}`,
    icon: <CalendarOutlined />
  },
  SELF_ASSESSMENT: {
    label: 'Ինքնագնահատում',
    url: `${PATHS.SELF_ASSESSMENT}`,
    icon: <CopyOutlined />
  },
};